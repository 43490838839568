.home-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  min-height: 3rem;
}

.home-section:nth-child(even) {
  background-color: black!important;
  color: white!important;
}

.home-section-title-container {
  display: flex;
  flex-direction: row;
  position: sticky;
  z-index: 2;
  background-color: inherit;
  width: 100vw;
}

.home-section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: inherit;
}

.wave {
  font-weight: bold;
  min-width: 10px;
}

.home-section-title:hover {
  & .wave {
    animation: wave 0.35s ease-in 1 normal;
  }
}

@keyframes wave {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}
