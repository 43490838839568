/* pre-web font */
body {
  font-size: 16px;
  font-weight: 100;
  letter-spacing: -0.45px;
  word-spacing: 0px;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.fonts-loaded {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1.3px;
  visibility: visible;
}

@keyframes drop {
  from {
    top: 0%;
    opacity: 0;
  }
  to {
    height: 4vh;
    opacity: 1;
  }
}

@keyframes nav {
  from {
    opacity: 0;
    top: -50%;
    position: absolute;
  }
  to {
    opacity: 75%;
    top: 100%;
  }
}

/* unvisited link */
a:link {
  color: inherit;
}

/* visited link */
a:visited {
  color: inherit;
}

/* mouse over link */
a:hover {
  color: #8CA6B5;
}

/* selected link */
a:active {
  color: rgb(220, 200, 195);
}

ul {
  padding-inline-start: 0px
}