.experience-container {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  height: 80%;
  padding-bottom: 10rem;
  position: sticky;
  top: 0;
}

.experience-title {
  position: sticky;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.experience-detail {
  color: white;
  background-color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}