.projects-container {
  display: flex;
  flex-direction: column;
}

.project-title {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: fit-content;
}

.game-container {
  display: flex;
  flex-direction: column;
  border: 4px solid;
  height: 600px;
  text-align: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  text-shadow: 0 0 3px #000000;
}


