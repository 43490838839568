.navParent {
  position: sticky;
  top: 0px;
  z-index: 10;
  box-sizing: border-box;
}

.navOuter {
  display: flex;
  flex-direction: row;
  height: 10vh;
  width: 100%;
  background-color: black;
  align-items: center;
  box-sizing: border-box;
  color: white;
}

.navInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  animation-name: nav;
  animation-duration: 6.3s;
}

.mobileDrop {
  top: 90%;
  position: absolute;
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  text-align: center;
  background-color: black;
  color: white;
}

.socialOuter {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.logo {
  height: 2.3rem;
  width: 2.3rem;
  margin-left: 2rem;
}